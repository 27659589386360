<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")

    b-tabs
      b-tab(title="Main")
        form(@submit.prevent="submit", v-if="placement")
          .row
            .col-md-12
              .widget
                .widget-header
                  h1.title {{ placement.id ? 'Edit placement' : 'Create placement' }}
                  .buttons.text-right
                    router-link.btn.btn-default(:to="{name:'placement-list'}")
                      i.la.la-angle-left
                      | Back
                    | &nbsp;
                    button.btn.btn-primary(type="submit", :disabled="!!placement.reserved") Save
                      i.la.la-fw.la-save
                .widget-body
                  div.info-pane
                    div.info-row
                      span.info-label Offer
                      span.info-value [<span class="hl">{{ offer.id }}</span>] {{ offer.name }} &nbsp;
                        router-link(:to="{name:offer.type==='complex' ? 'complex-edit' : 'offer-edit', params: {id: offer.id}}", target="_blank")
                          i.la.la-external-link
                    div.info-row
                      span.info-label Advertiser
                      span.info-value [<span class="hl">{{ advertiser.id }}</span>] {{ advertiser.name }} &nbsp;
                        router-link(:to="{name:'advertiser-edit', params: {id: advertiser.id}}", target="_blank")
                          i.la.la-external-link
                    div.info-row
                      span.info-label Publisher
                      span.info-value [<span class="hl">{{ publisher.id }}</span>] {{ publisher.name }} &nbsp;
                        router-link(:to="{name:'publisher-edit', params: {id: publisher.id}}", target="_blank")
                          i.la.la-external-link
                    div.info-buttons
                      router-link(:to="{name:'reports', query:{placement_id:placement.id}}", target="_blank")
                        .btn.btn-circle.btn-primary(v-b-tooltip.hover.bottom, title="Statistics")
                          i.la.la-bar-chart

                  .row
                    .col-sm-6
                      .form-group(v-if="placement.id")
                        label Placement ID
                        input.form-control(type="text", placeholder="", readonly="readonly", v-model="placement.id")
                    .col-sm-6
                      .form-group
                        label Status

                        .input-group
                          select.form-control(v-model="placement.status", name="status", :disabled="placement.auto_generated")
                            option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
                          .input-group-append(v-if="placement.auto_generated")
                            button.btn.btn-secondary(type="button", @click="placement.auto_generated = false", v-b-tooltip.hover.left, title="Click to cancel Auto-Connect on this placement")
                              i.la.la-robot

                  p.clearfix &nbsp;
                  //.form-group
                    label Name (Inner Use)
                    input.form-control(type="text", placeholder="", v-model="placement.name")
                  .form-group
                    label
                      div.toggle-wrapper
                        toggle-button(v-model="overrideName", :width="40", :height="20", :font-size="14")
                        span.lbl Override Name
                    input.form-control(v-if="overrideName", type="text", v-model="placement.override_name")
                    //input.form-control(type="text", placeholder="", v-model="placement.override_name")

                  //.form-group
                    label Custom Fallback URL
                    input.form-control(type="text", placeholder="http://.../", v-model="placement.fallback_url")
            .col-md-6
              .widget
                .widget-body
                  h4.widget-section-title Info
                  .row
                    .col-sm-12
                      .form-group
                        label Description
                          i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Leave blank to use offer's description")
                        //textarea.form-control(:placeholder="offer.description", v-model="placement.description", rows="5")
                        b-input-group
                          b-form-textarea(rows="5", placeholder="", v-model="placement.description")
                          b-input-group-append
                            b-button(type="button", v-clipboard:copy="placement.description", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
                              v-b-tooltip.hover.bottom, title="Copy to clipboard")
                              i.la.la-copy
                    .col-sm-12
                      .form-group
                        label User Flow
                          i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Leave blank to use offer's user flow")
                        //textarea.form-control(:placeholder="offer.user_flow", v-model="placement.user_flow", rows="5")
                        b-input-group
                          b-form-textarea(rows="5", placeholder="", v-model="placement.user_flow")
                          b-input-group-append
                            b-button(type="button", v-clipboard:copy="placement.user_flow", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
                              v-b-tooltip.hover.bottom, title="Copy to clipboard")
                              i.la.la-copy

                  p.clearfix
                  h4.widget-section-title Payout
                  .row
                    .col-sm-6
                      label &nbsp;
                        div.toggle-wrapper
                          toggle-button(v-model="placement.auto_payout", :width="40", :height="20", :font-size="14")
                          span.lbl Automatic payout
                    .col-sm-6
                      .form-group
                        label Payout Mode
                        select.form-control(v-model="placement.payout_mode")
                          option(value="fixed") Fixed
                          option(value="dynamic") Dynamic
                  .row
                    .col-sm-6
                      .form-group
                        label Payout
                        input.form-control(type="text", placeholder="0.00", v-model="placement.payout")
                        p.form-text Offer payout: <span v-if="offer">{{ offer.payout }}</span>
                    .col-sm-6
                      .form-group
                        label Currency
                        //input.form-control(type="text", placeholder="USD", v-model="placement.currency")
                        select.form-control(v-model="placement.currency")
                          option(v-for="o in currencyOptions", :value="o.v", :disabled="o.disabled") {{ o.t }}
                  .row
                    .col-sm-6
                      .form-group
                        label Payout Model
                        select.form-control(v-model="placement.payout_type")
                          option(v-for="o in payoutTypeOptions", :value="o.v") {{ o.v }}
                        p.form-text Offer payout type: <span v-if="offer">{{ offer.payout_type }}</span>

                    .col-sm-6(v-if="placement.payout_type === 'CPA'")
                      .form-group
                        label Payable Event
                        select.form-control(v-model="placement.payable_event_name")
                          option(v-for="o in offer.events", :value="o.name") {{ o.name }}
                          option(v-if="offer_payable_event", :value="null") -- As defined in the offer ({{offer_payable_event}}) --
                        p.form-text(v-if="offer_payable_event") Offer payable event: {{ offer_payable_event }}
                      .form-group
                        b-form-checkbox(v-model="placement.allow_multiple_conversions") Allow multiple conversions for the same click

                  p.clearfix &nbsp;
                  div(v-if="USER.permissions['placements::RISK']")
                    h4.widget-section-title Risk
                    .row
                      .col-sm-4
                        .form-group
                          label &nbsp;
                          div.toggle-wrapper
                            toggle-button(v-model="placement.custom_report", :width="40", :height="20", :font-size="14")
                            span.lbl Override auto-report
                              i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="When enabled - publisher's risk is ignored and determined by this configuration")
                      .col-sm-8(v-if="placement.custom_report")
                        .form-group
                          label Risk Management
                          .input-group
                            input.form-control(type="text", placeholder="", v-model.number="placement.risk_management")
                            div.input-group-append
                              span.input-group-text %

                    .row
                      .col-sm-12
                        .form-group
                          label &nbsp;
                          div.toggle-wrapper
                            toggle-button(v-model="placement.report_offer_closed", :width="40", :height="20", :font-size="14")
                            span.lbl Keep reporting even if offer is closed

                      .col-sm-12
                        .form-group
                          label &nbsp;
                          div.toggle-wrapper
                            toggle-button(v-model="placement.report_on_overcap", :width="40", :height="20", :font-size="14")
                            span.lbl Keep reporting even if conversion cap reached (placement or offer)
                      .col-sm-6
                        .form-group
                          label Risk mode
                          select.form-control(v-model="placement.risk_calc_mode")
                            option(value="daily") Daily
                            option(value="normal") From Date

                      .col-sm-6(v-if="placement.risk_calc_mode === 'normal'")
                        .form-group
                          label Calculate risk from
                          .input-group
                            input.form-control(type="text", v-model="placement.risk_calc_from", placeholder="Calculate from")
                            div.input-group-append
                              a.input-group-text(href="javascript:void(0)", @click="setCalcFromNow()")
                                i.la.la-clock-o

              .widget(v-if="USER.config && (USER.config.s2sOffer || USER.config.captchaForPlacements)")
                .widget-header
                  h2.title Tracking
                .widget-body
                  .form-group(v-if="USER.config.s2sOffer")
                    b-form-checkbox(v-model="placement.s2s") S2S Support (Server-To-Server)
                  .form-group(v-if="USER.config.captchaForPlacements")
                    b-form-checkbox(v-model="placement.is_captcha_enabled") Enable Captcha
                      new-feature 

              os-network-targeting(v-model="osNetworkParams")

              device-type-targeting(v-model="placement.device_type_blacklist", :is-new="true")

            .col-sm-6
              .widget
                .widget-body
                  h4.widget-section-title Filters
                  .row
                    .col-sm-4
                      .form-group
                        label Redirect Type
                        select.form-control(v-model="placement.redirect_type")
                          option(v-for="o in redirectTypeOptions", :value="o.v") {{ o.t }}
                  .row
                    .col-sm-4
                      .form-group
                        label &nbsp;
                        div.toggle-wrapper
                          toggle-button(v-model="placement.block_iframe", :width="40", :height="20", :font-size="14")
                          span.lbl Block iframe
                    .col-sm-4(v-if="USER.config.blockSuspiciousHeaders")
                      .form-group
                        label &nbsp;
                        div.toggle-wrapper
                          toggle-button(v-model="placement.block_suspicious_headers", :width="40", :height="20", :font-size="14")
                          span.lbl Block Suspicious Headers
                    .col-sm-4
                      .form-group
                        label &nbsp;
                        div.toggle-wrapper
                          toggle-button(v-model="placement.block_proxies", :width="40", :height="20", :font-size="14")
                          span.lbl Block proxies
                    //.col-sm-4
                      .form-group
                        label &nbsp;
                        div.toggle-wrapper
                          toggle-button(v-model="placement.validate_redirect", :width="40", :height="20", :font-size="14")
                          span.lbl Validate redirect

                  h4.widget-section-title Whitelist/Blacklist Parameters
                  b-tabs.wl-bl-tabs
                    b-tab(v-for="f in blwlFields", v-bind:key="f.v")
                      template(#title)
                        span(:class="'icon-'+placement[f.v+'_blwl_mode']")
                          i.la.la-dot-circle &nbsp;
                        span {{ f.t }}
                      div(style="padding: 15px;")
                        div.form-inline
                          .form-group(style="line-height:21px;")
                            label(style="padding-right: 20px;") Mode:
                            b-form-radio-group(v-model="placement[f.v+'_blwl_mode']", :name="f.v+'_blwl_mode'")
                              b-form-radio(:value="null") None
                              b-form-radio(value="blacklist") Blacklist
                              b-form-radio(value="whitelist") Whitelist
                        br
                        .row
                          .col-sm-6
                            .form-group
                              label Blacklist
                              textarea.form-control(rows="5", placeholder="", v-model="placement[f.v+'_blacklist']", :disabled="placement[f.v+'_blwl_mode']!=='blacklist'")
                              b-form-checkbox(v-if="f.v=== 'sub1' && placement.sub1_blwl_mode==='blacklist'", v-model="placement.sub1_partial_match", @change="onPartialMatchToggle") Partial Match
                                span.ml-1(v-if="placement.sub1_partial_match_count>0") ({{placement.sub1_partial_match_count}})
                                i.la.la-cog.ml-1(id="popover-partial-blacklist")
                                b-popover(target="popover-partial-blacklist", title="Sub1 Partial Match", triggers="hover click", :show.sync="showPartialMatchPopover", placement="right", ref="popover")
                                  .form-group
                                    p.form-text.text-muted When active - blacklist will take into account sub1 that their first X "matching parts" match the list
                                    p.form-text.text-muted For example sub1 "123_456_789" will be blocked if the list will include "123_456" and the number of matching parts will be set to 2
                                  .form-group
                                    label Number of matching parts
                                    input.form-control(type="number", v-model="placement.sub1_partial_match_count", min="0", max="3", step="1", @change="onPartialMatchCountChange")
                                    p.form-text
                                      span(v-for="i in [1, 2, 3, 4, 5]", :class="{'text-danger': placement.sub1_partial_match_count >=i}") {{i>1?'_':''}}xxxxx
                          .col-sm-6
                            .form-group
                              label Whitelist
                              textarea.form-control(rows="5", placeholder="", v-model="placement[f.v+'_whitelist']", :disabled="placement[f.v+'_blwl_mode']!=='whitelist'")
                              b-form-checkbox(v-if="f.v=== 'sub1' && placement.sub1_blwl_mode==='whitelist'", v-model="placement.sub1_partial_match", @change="onPartialMatchToggle") Partial Match
                                span.ml-1(v-if="placement.sub1_partial_match_count>0") ({{placement.sub1_partial_match_count}})
                                i.la.la-cog.ml-1(id="popover-partial-whitelist")
                                b-popover(target="popover-partial-whitelist", title="Sub1 Partial Match", triggers="hover click", :show.sync="showPartialMatchPopover", placement="right", ref="popover")
                                  .form-group
                                    p.form-text.text-muted When active - whitelist will take into account sub1 that their first X "matching parts" match the list
                                    p.form-text.text-muted For example sub1 "123_456_789" will be allowed if the list will include "123_456" and the number of matching parts will be set to 2
                                  .form-group
                                    label Number of matching parts
                                    input.form-control(type="number", v-model="placement.sub1_partial_match_count", min="0", max="3", step="1", @change="onPartialMatchCountChange")
                                    p.form-text
                                      span(v-for="i in [1, 2, 3, 4, 5]", :class="{'text-danger': placement.sub1_partial_match_count >=i}") {{i>1?'_':''}}xxxxx

                  h4.widget-section-title Timeframe restriction
                  timeframe-restriction(:params="timeframeParams", :parent-settings="offerTimeframeParams", v-on:update-timeframe="updateTimeframe")


              .widget
                .widget-body
                  h4.widget-section-title Caps
                  .row(v-if="!useClicksRatio")
                    .col-sm-6
                      .form-group
                        .d-flex.justify-content-between
                          label Daily Clicks Cap
                          b-checkbox(
                            v-if="USER.config.clickRatioCap && publisher.media_type !== 'rewarded'",
                            switch,
                            v-model="useClicksRatio",
                            v-b-tooltip,
                            title="Caps should be fixed or automatic based on a traffic"
                          )
                        number-input(v-model="placement.daily_click_cap")
                        p.form-text.text-muted
                          span(v-if="offer") Offer: {{ offer.daily_click_cap | numberCommas }}
                          | /
                          span(v-if="publisher") Publisher: {{ publisher.default_placement_cap | numberCommas }}
                    .col-sm-6
                      .form-group
                        label Daily Impression Cap
                        number-input(v-model="placement.daily_impression_cap")
                        p.form-text(v-if="placement.daily_click_cap > 0 && placement.daily_impression_cap === 0") Paused on click cap &nbsp;
                          i.la.la-info-circle(v-b-tooltip.hover.bottom, title="There is no cap on impressions, but once the click cap is reached placement will be paused")
                        p.form-text.text-muted
                          span(v-if="offer") Offer: {{ offer.daily_impression_cap | numberCommas }}
                  .row(v-else)
                    .col-sm-6
                      ClickRatio(v-model="placement.click_ratio_cap", :use-clicks-ratio="useClicksRatio", @disable="useClicksRatio = false")
                  .row
                    .col-sm-6
                      .form-group
                        label Daily Conversion Cap (Actual)
                        number-input(v-model="placement.daily_conversion_cap")
                        p.form-text.text-muted(v-if="offer")
                          div Offer: {{ offer.daily_conversion_cap | numberCommas }}
                          div Effective Risk: {{ effective_risk }} %
                          div Visible Cap: {{ visible_cap | numberCommas }}
                    .col-sm-6(v-if="placement.payout_type === 'CPA' || !placement.payout_type && offer.payout_type === 'CPA'")
                      .form-group
                        label Daily Install Cap
                        number-input(v-model="placement.daily_install_cap")
                        p.form-text.text-muted(v-if="offer")
                          div Offer: {{ offer.daily_install_cap | numberCommas }}
                  .row
                    .col-sm-6
                      .form-group
                        label &nbsp;
                        div.toggle-wrapper
                          toggle-button(v-model="placement.use_publisher_subsource_cap", :width="40", :height="20", :font-size="14")
                          span.lbl Use publisher subsource caps
                    .col-sm-6
                      .form-group
                        label Daily Subsource Cap
                        number-input(v-model="placement.default_subsource_cap")
                        p.form-text.text-muted(v-if="publisher") Publisher: {{ publisher.default_subsource_cap }}

                  .row
                    .col-sm-6
                      .form-group
                        label Click pacing (Minute)
                        number-input(v-model="placement.click_pacing")
                        p.form-text.text-muted(v-if="!placement.click_pacing") No limit of clicks / minute
                        p.form-text(v-if="placement.click_pacing") Max {{placement.click_pacing | numberCommas }} clicks / minute
                    .col-sm-6
                      .form-group
                        label Click pacing (Hour)
                        number-input(v-model="placement.click_pacing_hour")
                        p.form-text.text-muted(v-if="!placement.click_pacing_hour") No limit of clicks / hour
                        p.form-text(v-if="placement.click_pacing_hour") Max {{placement.click_pacing_hour | numberCommas }} clicks / hour
                  .row
                    .col-sm-6
                      .form-group
                        label Impression pacing (Minute)
                        number-input(v-model="placement.impression_pacing")
                        p.form-text.text-muted(v-if="!placement.impression_pacing") No limit of impressions / minute
                        p.form-text(v-if="placement.impression_pacing") Max {{placement.impression_pacing | numberCommas }} impressions / minute
                    .col-sm-6
                      .form-group
                        label Impression pacing (Hour)
                        number-input(v-model="placement.impression_pacing_hour")
                        p.form-text.text-muted(v-if="!placement.impression_pacing_hour") No limit of impressions / hour
                        p.form-text(v-if="placement.impression_pacing_hour") Max {{placement.impression_pacing_hour | numberCommas }} impressions / hour

                  p.clearfix &nbsp;
                  h4.widget-section-title(v-if="(USER.config && USER.config.impressionRate) || USER.special_account_features") Misc
                  .row(v-if="USER.config && USER.config.impressionRate")
                    .col-sm-6
                      impression-ratio(v-model="placement.impression_rate")


                  // div(v-if="USER.special_account_features")
                    .row
                      .col-sm-6
                        .form-group
                          label Send % of pixel traffic to fallback
                          .input-group(style="width: 90px;")
                            input.form-control(style="padding: 7px 4px; text-align: center;", type="number", v-model.number="placement.smartlink_pixel", max="100", min="-1")
                            .input-group-append
                              .input-group-text %
                          p.form-text -1 = use publisher default: {{ publisher.default_smartlink_pixel }}

                  .row
                    .col-sm-12
                      .form-group
                        label Comments (Internal use)
                        textarea.form-control(rows="3", placeholder="Comments (Internal use)", v-model="placement.comments")

      b-tab(title="Activity Log", v-if="placement.id")
        .widget
          .widget-body
            activity-log-table(type="placement", :id="placement.id")

</template>
<style lang="scss">
.wl-bl-tabs {
  .icon-blacklist {
    color: #dc3545;
  }
  .icon-whitelist {
    color: #28a745;
  }
  .icon-null {
    display: none;
  }
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';
import ClickRatio from '../../components/form/ClickRatio.vue';

export default {
  name: 'PlacementForm',
  components: { ClickRatio },
  async beforeRouteEnter(to, from, next) {
    let placement = {};

    let advertiserOptions = await Vue.ovData.advertiser.getOptions();

    if (to.name === 'placement-edit') {
      let entityId = +to.params.id;
      placement = await Vue.ovData.placement.get(entityId, true);
    } else {
      placement = Vue.ovData.placement.newInstance();
      let placementDefaults = await Vue.ovData.placement.getDefaults(false);
      Object.assign(placement, placementDefaults);
    }

    let offer = null;
    let publisher = null;
    if (placement.offer_id) {
      offer = await Vue.ovData.offer.get(placement.offer_id);
      placement.advertiser_id = offer.advertiser_id;
    } else if (+to.query.offer_id) {
      offer = await Vue.ovData.offer.get(+to.query.offer_id);
      placement.offer_id = offer.id;
      placement.advertiser_id = offer.advertiser_id;
    }
    if (!offer) {
      return next(false);
    }
    let advertiser = await Vue.ovData.advertiser.get(offer.advertiser_id);

    if (placement.publisher_id) {
      publisher = await Vue.ovData.publisher.get(placement.publisher_id);
    } else if (+to.query.publisher_id) {
      publisher = await Vue.ovData.publisher.get(+to.query.publisher_id);
      placement.publisher_id = publisher.id;
    }

    next(async (vm) => {
      vm.placement = placement;

      vm.osNetworkParams = {
        carrier_whitelist: placement.carrier_whitelist,
        isp_whitelist: placement.isp_whitelist,
        should_block_wifi: placement.should_block_wifi || false,
        os_targeting: placement.os_targeting,
        os_targeting_version_min: placement.os_targeting_version_min,
        os_targeting_version_max: placement.os_targeting_version_max,
        os_version_blacklist: placement.os_version_blacklist,
        advanced_targeting_whitelist: placement.advanced_targeting_whitelist,
        advanced_targeting_blacklist: placement.advanced_targeting_blacklist,
      };

      vm.advertiser = advertiser;
      vm.advertiserOptions = advertiserOptions;
      vm.publisher = publisher;
      vm.offer = offer;
      vm.overrideName = !!placement.override_name;
      vm.timeframeParams = {
        restrict_timeframe_schedule: placement.restrict_timeframe_schedule,
        restrict_timeframe_timezone: placement.restrict_timeframe_timezone,
        restrict_timeframe: placement.restrict_timeframe,
      };
      if (offer.restrict_timeframe && offer.restrict_timeframe_schedule) {
        vm.offerTimeframeParams = {
          restrict_timeframe_schedule: offer.restrict_timeframe_schedule,
          restrict_timeframe_timezone: offer.restrict_timeframe_timezone,
          restrict_timeframe: offer.restrict_timeframe,
        };
      }

      if (placement.click_ratio_cap > 0) {
        vm.useClicksRatio = true;
      }

      return vm;
    });
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    effective_risk() {
      return this.placement.custom_report
        ? this.placement.risk_management
        : this.publisher.auto_report_risk;
    },
    visible_cap() {
      return Math.floor(
        (this.effective_risk / 100) *
          (this.placement.daily_conversion_cap || this.offer.daily_conversion_cap),
      );
    },
    offer_payable_event() {
      if (!this.offer || this.offer.payout_type !== 'CPA') {
        return false;
      }
      return this.offer.events
        .filter((e) => e.payout_mode !== 'none' && !!e.payout)
        .map((e) => e.name)
        .join(',');
    },
  },
  data() {
    return {
      busy: false,
      advertiser: null,
      offer: null,
      publisher: null,
      placement: null,
      osNetworkParams: {},
      useOfferName: true,
      overrideName: false,
      timeframeParams: {},
      offerTimeframeParams: null,
      // pauseReasonOptions: [
      // 	{code: 'none', label: 'None'},
      // 	{code: 'low_cr', label: 'Low CR'},
      // 	{code: 'fraud', label: 'Fraud'},
      // 	{code: 'not_converting', label: 'Not converting'},
      // 	{code: 'retention', label: 'Retention'},
      // 	{code: 'advertiser_request', label: 'Advertiser Request'},
      // 	{code: 'custom', label: 'Custom'},
      // ],
      advertiserOptions: [],
      publisherOptions: [],
      offerOptions: [],
      useClicksRatio: false,
      payoutTypeOptions: Vue.ovData.general.payoutTypeOptions,
      currencyOptions: Vue.ovData.general.currencyOptions,
      statusOptions: [
        { v: 'draft', t: 'Draft' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'cancelled', t: 'Cancelled' },
        // {v: 'ended', t: 'Ended'}
      ],
      redirectTypeOptions: [
        { v: '200', t: 'HTTP 200 (After page load)' },
        { v: '302', t: 'HTTP 302 (Default)' },
      ],
      showPartialMatchPopover: false,
      blwlFields: [
        { v: 'sub1', t: 'Subsource' },
        { v: 'sub2', t: 'Sub 2' },
      ],
    };
  },
  watch: {
    useClicksRatio(newValue) {
      if (newValue) {
        this.placement.daily_click_cap = 0;
        this.placement.daily_impression_cap = 0;
      }
    },
  },
  methods: {
    updateTimeframe(params) {
      this.placement.restrict_timeframe = params.restrict_timeframe;
      this.placement.restrict_timeframe_timezone = params.restrict_timeframe_timezone;
      this.placement.restrict_timeframe_schedule = params.restrict_timeframe_schedule;
    },
    onPartialMatchToggle(e) {
      if (!this.placement.sub1_partial_match_count && e) {
        this.placement.sub1_partial_match_count = 2;
      }
      if (e) {
        this.showPartialMatchPopover = true;
      }
    },
    onPartialMatchCountChange() {
      this.placement.sub1_partial_match = this.placement.sub1_partial_match_count > 0;
    },
    setCalcFromNow() {
      this.placement.risk_calc_from = moment().format('DD/MM/YYYY HH:mm');
    },
    async loadPublisher() {
      if (!this.placement.publisher_id) {
        this.publisher = null;
      }
      this.publisher = await this.$ovData.publisher.get(this.placement.publisher_id);
    },
    async loadOffer() {
      if (!this.placement.offer_id) {
        this.offer = null;
      }
      this.offer = await this.$ovData.offer.get(this.placement.offer_id);
    },
    async fetchOfferOptions(search, loading) {
      if (!this.placement.advertiser_id) {
        this.offerOptions = [];
        return false;
      }
      loading(true);
      let params = {
        advertiser_id: this.placement.advertiser_id,
        limit: 20,
        keyword: search,
      };
      let resp = await Vue.http.get('offer/getOptions', { params });
      this.offerOptions = resp.data.data.options;
      loading(false);
    },

    /**
     * Convert a version string into a number.
     * @param {string} version - The version string to convert in the format "major.minor.patch.build".
     * @returns {number} - The version number.
     */
    versionToNumber(version) {
      const numbers = version.split('.').map(Number);

      while (numbers.length < 4) {
        numbers.push(0);
      }

      const [major, minor, patch, build] = numbers;
      return major * 1000000 + minor * 10000 + patch * 100 + build;
    },

    async submit() {
      if (this.busy) {
        return;
      }
      if (
        this.placement.payout_type === 'CPA' &&
        this.offer.payout_type !== 'CPA' &&
        !this.placement.payable_event_name
      ) {
        return this.$ovNotify.error('You must select a payable event for CPA Payout Model');
      }
      if (this.placement.reserved) {
        return this.$ovNotify.error('You are not authorized to perform this action');
      }
      let data = Vue.util.extend({}, this.placement);
      if (!data.sub1_partial_match) {
        data.sub1_partial_match_count = 0;
      }

      Vue.ovData.placement._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });

      data.carrier_whitelist = this.osNetworkParams.carrier_whitelist;
      data.isp_whitelist = this.osNetworkParams.isp_whitelist;
      data.should_block_wifi = this.osNetworkParams.should_block_wifi;
      data.os_targeting = this.osNetworkParams.os_targeting;
      data.os_targeting_version_min = this.osNetworkParams.os_targeting_version_min;
      data.os_targeting_version_max = this.osNetworkParams.os_targeting_version_max;
      data.os_version_blacklist = this.osNetworkParams.os_version_blacklist;
      data.advanced_targeting_whitelist = this.osNetworkParams.advanced_targeting_whitelist;
      data.advanced_targeting_blacklist = this.osNetworkParams.advanced_targeting_blacklist;

      if (
        data.os_targeting_version_min &&
        Number.isNaN(this.versionToNumber(data.os_targeting_version_min))
      ) {
        return this.$ovNotify.error('OS minimum version is not a valid version number');
      }

      if (
        data.os_targeting_version_max &&
        Number.isNaN(this.versionToNumber(data.os_targeting_version_max))
      ) {
        return this.$ovNotify.error('OS maximum version is not a valid version number');
      }

      if (data.os_targeting_version_min && data.os_targeting_version_max) {
        const minNumber = this.versionToNumber(data.os_targeting_version_min);
        const maxNumber = this.versionToNumber(data.os_targeting_version_max);
        if (minNumber > maxNumber) {
          return this.$ovNotify.error('OS version min must be less than OS version max');
        }
      }

      try {
        this.busy = true;
        await this.$ovReq.post('placement/save', data);
        this.busy = false;
        this.$ovNotify.success('Placement has been saved');
        this.$router.push({ name: 'placement-list' });
      } catch (e) {
        console.error(e);
        this.busy = false;
      }
    },

    onCopySuccess() {
      this.$ovNotify.success('Copied to clipboard');
    },

    onCopyError() {
      this.$ovNotify.error('Could not copy text');
    },
  },
};
</script>
